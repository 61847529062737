<template>
  <VueTrix
    class="s-trix-editor"
    :input-name="name"
    :src-content="value"
    :placeholder="placeholder"
    :disabled-editor="readonly"
    @update="$emit('input', $event)"
    @trix-file-accept="handleFileAccept"
  />
</template>

<script>
  import VueTrix from 'vue-trix'

  export default {
    components: { VueTrix },
    props: {
      value: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      readonly: {
        type: Boolean,
      },
    },
    methods: {
      handleFileAccept (e) {
        e.preventDefault()
      },
    },
  }
</script>

<style scoped>
  .s-trix-editor::v-deep .trix-content {
    min-height: 10em;
  }

  .s-trix-editor::v-deep .trix-button-group--file-tools {
    display: none;
  }
</style>
